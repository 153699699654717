import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import About from "./about";
import './App.css' 
import Bitcoin from "./bitcoin";
import Contract from "./contract";
import Experts from "./experts";
import HeaderA from "./headerA";
import HeaderB from "./headerB";
import HeaderC from "./headerC";
import Invest from "./invest";
import NavA from "./navA";
import NavB from "./navB";
import NavC from "./navC";
import SlideShow from "./slideshow";
import Started from "./started";
import Statistics from "./statistics";
import World from "./world";

const App = () => {
 
  const titleAstart = 'about' 
  const titleAend = 'us' 
  const paragraphA = 'lorem Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat id nostrum odit.'

  const titleBstart = 'our' 
  const titleBend = 'experts' 
  const paragraphB = 'lorem Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat id nostrum odit.'

  const titleCstart = 'OUR company' 
  const titleCend = 'statistics' 
  const paragraphC = ''


    return ( 
        <Router>
          <main className="app">               
            <Switch>
              
              <Route exact path="/">
                <NavA />
                <NavC />
                <NavB />
                <SlideShow />
                <Bitcoin />
                <HeaderA titleAstart={titleAstart} titleAend={titleAend} paragraph={paragraphA}/>
                <About />
                <World />
                <HeaderB title="investment offers"/>
                <Invest />
                <HeaderB title="Contract Plans"/>
                <Contract />
                <HeaderC titleAstart={titleCstart} titleAend={titleCend} paragraph={paragraphC}/>
                <Statistics />
                <HeaderA titleAstart={titleBstart} titleAend={titleBend} paragraph={paragraphB}/>
                <Experts />
                <Started />
              </Route>


              <Route path="*">
              </Route>

            </Switch>
          </main>
      </Router>
   );
}
export default App;
 