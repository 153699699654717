import './experts.css'

const Experts = () => {

    const experts = [
        {
            name: 'Banks Dylan Brain',
            post: 'CEO FOUNDER'
        },
        {
            name: 'Hernandez Bilski',
            post: 'DIRECTOR'
        },
        {
            name: 'Tory Graham Nick',
            post: 'BITCOIN DEVELOPER / CTO '
        },
        {
            name: 'Janet Joy McPherson',
            post: 'BITCOIN CONSULTANT / CFO '
        },
    ]
    
    return ( 
        <main className="experts">
            <div className="cubes">
                {experts.map((expert)=>(
                    <div className="cube">
                        <div className="circle"></div>
                        <p>{expert.name}</p>
                        <p>{expert.post}</p>
                    </div>
                ))}
                
            </div>
        </main>
     );
}
 
export default Experts; 