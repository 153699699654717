import './navc.css'

const NavC = () => {
    const Hamburger = <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" aria-labelledby="hamburgerIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="hamburgerIconTitle">Menu</title> <path d="M6 7L18 7M6 12L18 12M6 17L18 17"/> </svg>

    const Cancel = <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" aria-labelledby="cancelIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="cancelIconTitle">Cancel</title> <path d="M15.5355339 15.5355339L8.46446609 8.46446609M15.5355339 8.46446609L8.46446609 15.5355339"/> <path d="M4.92893219,19.0710678 C1.02368927,15.1658249 1.02368927,8.83417511 4.92893219,4.92893219 C8.83417511,1.02368927 15.1658249,1.02368927 19.0710678,4.92893219 C22.9763107,8.83417511 22.9763107,15.1658249 19.0710678,19.0710678 C15.1658249,22.9763107 8.83417511,22.9763107 4.92893219,19.0710678 Z"/> </svg>
    

    const hamburger = () => {
        document.querySelector('.others').classList.toggle('othersslide');
    }
    
    return ( 
        <main className="NavC">
            <div className="navc">
                <div className="top">
                    <p>BRIXTON</p>
                    <p onClick={hamburger}>{Hamburger}</p>
                </div>
                <div className="others">
                    <ul>
                        <li><a href="">home</a></li>
                        <li><a href="">login / register </a></li>
                        <li>search</li>
                        <li><a href="">about us</a></li>
                        <li><a href="">services</a></li>
                        <li><a href="">plans</a></li>
                        <li><a href="">faq</a></li>
                        <li><a href="">terms of services</a></li>
                        <li><a href="">contact</a></li>
                        <li><a href="">account</a></li>
                    </ul>
                </div>
            </div>
        </main>
     );
}
 
export default NavC;