import './about.css'

const About = () => {



    return ( 
        <main className="about">
            <div className="container">
                <div className="leftt"></div>
                <div className="right">
                    <p>WE ARE BRIXTON.COM </p>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit voluptates dicta consectetur ipsum obcaecati mollitia necessitatibus, vero pariatur. Hic repellat, asperiores ex soluta dolore magnam minima vitae aspernatur error impedit ut unde ad?</p>
                    <p>
                        <p className='motion'>OUR MOTION</p>
                        <p className='advantage'>OUR ADVANTAGES</p>
                        <p className='guarantee'>OUR GUARANTEES</p>
                    </p>
                   
                    <p className="changetext">
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates eligendi voluptatem blanditiis quod quo beatae at aspernatur. Totam, ex saepe!
                    </p>
                    <p>READ MORE</p>
                </div>
            </div>
        </main>
     );
}
 
export default About;