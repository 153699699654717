import './started.css'


const Started = () => {
    return ( 
        <main className="started">
            <p>GET STARTED TODAY WITH BITCOIN</p>
            <p>Open an account for free and start trading Bitcoins!</p>
            <p>REGISTER NOW</p>
        </main>
     );
}
 

export default Started;