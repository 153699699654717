import './world.css'

const World = () => {

    const worldInfos = [
        {
            header: 'STRONG SECURITY',
            text: 'Protection against DDoS attacks, full data encryption',
            img: <svg role="img" xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24" aria-labelledby="octagonIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="octagonIconTitle">Octagon</title> <polygon points="16 3 21 8 21 16 16 21 8 21 3 16 3 8 8 3"/> </svg>
        },
        {
            header: 'WORLD COVERAGE',
            text: 'Providing services in 99% countries around the globe',
            img: <svg width="35px" height="35px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="originIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" color="white"> <title id="originIconTitle">Origin</title> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"/> <path d="M12 2V8"/> <path d="M12 16V22"/> <path d="M2 12L8 12"/> <path d="M16 12L22 12"/> <circle cx="12" cy="12" r="1"/> </svg>
        },
        {
            header: 'PAYMENT OPTIONS',
            text: 'Popular methods: Fast and easy block chain technology transfer',
            img: <svg role="img" xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24" aria-labelledby="cardsIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="cardsIconTitle">Cards</title> <rect width="13" height="13" x="3" y="3"/> <polyline points="16 8 21 8 21 21 8 21 8 16"/> </svg>
        },
        {
            header: 'MOBILE APP',
            text: 'Trading via our Mobile App. Available soon in Play Store and App Store',
            img: <svg width="35px" height="35px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-labelledby="appsIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="appsIconTitle">Apps drawer</title> <circle cx="6" cy="6" r="1"/> <circle cx="12" cy="6" r="1"/> <circle cx="18" cy="6" r="1"/> <circle cx="6" cy="12" r="1"/> <circle cx="12" cy="12" r="1"/> <circle cx="18" cy="12" r="1"/> <circle cx="6" cy="18" r="1"/> <circle cx="12" cy="18" r="1"/> <circle cx="18" cy="18" r="1"/> </svg>
        },
        {
            header: 'COST EFFICIENCY',
            text: 'Reasonable trading fees and investment plans for takers and all market makers',
            img: <svg width="35px" height="35px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="trainIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" color="white"> <title id="trainIconTitle">Train</title> <path d="M6 11V6M6 11V16C6 16.5523 6.44772 17 7 17H8M6 11H12M6 6C6.66667 5.33333 8 4 12 4C16 4 17.3333 5.33333 18 6M6 6H12M18 6V11M18 6H12M18 11V16C18 16.5523 17.5523 17 17 17H16M18 11H12M8 17H16M8 17L7 20H17L16 17M12 11V6"/> <circle cx="9" cy="14" r="1"/> <circle cx="15" cy="14" r="1"/> </svg>
        },
        {
            header: 'HIGH LIQUIDITY',
            text: 'Fast access to high liquidity orderbook for top currency pairs',
            img: <svg width="35px" height="35px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-labelledby="swapHorizontalIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="swapHorizontalIconTitle">Swap items (horizontally)</title> <path d="M16 4L19 7L16 10"/> <path d="M4 7L18 7"/> <path d="M7 20L4 17L7 14"/> <path d="M19 17L5 17"/> </svg>
        },

    ]

    return ( 
        <main className="world">
            <div className="worldcontainer">
                <div className="worldleft">
                    {worldInfos.map((info)=> (
                        <div id="worldleft">
                            <div className="img">{info.img}</div>
                            <p className="world">{info.header}</p>
                            <p>{info.text}</p>
                        </div>
                    ))}
                   
                </div>
                <div className="worldright"></div>
            </div>
        </main>
     );
}


export default World;