import './headers.css'

const HeaderC = ({titleAstart, titleAend, paragraph}) => {
    return ( 
        <div className="headerc">
        <p><span>{titleAstart} </span><span className="orangeheader">{titleAend}</span></p>
        {paragraph && 
            <div className="lines">
                <span className="line"></span>
                <p>{paragraph}</p>
                <span className="line"></span>
            </div>
        }
    </div>
     );
}
 

export default HeaderC;