import './slideshow.css'

const SlideShow = () => {
    return ( 
        <main className="slideshow">

            
        </main>
     );
}
 
export default SlideShow;