import './navb.css'

const NavB = () => {


    const Search = <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" aria-labelledby="searchIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="searchIconTitle">Search</title> <path d="M14.4121122,14.4121122 L20,20"/> <circle cx="10" cy="10" r="6"/> </svg>

    const Cancel = <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" aria-labelledby="cancelIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="cancelIconTitle">Cancel</title> <path d="M15.5355339 15.5355339L8.46446609 8.46446609M15.5355339 8.46446609L8.46446609 15.5355339"/> <path d="M4.92893219,19.0710678 C1.02368927,15.1658249 1.02368927,8.83417511 4.92893219,4.92893219 C8.83417511,1.02368927 15.1658249,1.02368927 19.0710678,4.92893219 C22.9763107,8.83417511 22.9763107,15.1658249 19.0710678,19.0710678 C15.1658249,22.9763107 8.83417511,22.9763107 4.92893219,19.0710678 Z"/> </svg>

    const slide = () => {
        document.querySelector('.searchinput').classList.toggle('slidesearch');
    }

    const cancel = () => {
        document.querySelector('.searchinput').classList.remove('slidesearch');
        document.querySelector('.inp').value="";
    }
    return ( 
        <main className="NavB">
          <div className="bigscreenNav">
            <div className="links">
            <ul>
                <li><a href="">HOME</a></li>
                <li><a href="">ABOUT US</a></li>
                <li><a href="">SERVICES</a></li>
                <li><a href="">PLANS</a></li>
                <li><a href="">FAQ</a></li>
                <li><a href="">TERMS OF SERVICES</a></li>
                <li><a href="">CONTACT</a></li>
                <li><a href="">ACCOUNT</a></li>
                <li onClick={slide}>{Search}</li>
            </ul>
            </div>
            <div className="searchinput">
                <input type="text" className='inp' placeholder='type in the field and hit enter'/>
                <p className='cancel' onClick={cancel}>{Cancel}</p>
            </div>
          </div>
        </main>
     );
}
 
export default NavB; 