import './nava.css'


const NavA = () => {


    const login = <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" aria-labelledby="personIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="personIconTitle">Person</title> <path d="M4,20 C4,17 8,17 10,15 C11,14 8,14 8,9 C8,5.667 9.333,4 12,4 C14.667,4 16,5.667 16,9 C16,14 13,14 14,15 C16,17 20,17 20,20"/> </svg>

    const signup = <svg width="48px" height="48px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-labelledby="personAddIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="personAddIconTitle">Add user</title> <path d="M1 18C1 15.75 4 15.75 5.5 14.25C6.25 13.5 4 13.5 4 9.75C4 7.25025 4.99975 6 7 6C9.00025 6 10 7.25025 10 9.75C10 13.5 7.75 13.5 8.5 14.25C10 15.75 13 15.75 13 18"/> <path d="M22 11H14"/> <path d="M18 7V15"/> </svg>

    return ( 
        <main className="NavA">
            <div className="nava">
                <div className="logo"><h1>Brixton</h1></div>
                <div className="data">
                    <div id="data">
                        <div className="data__top">10,020,950 USD</div>
                        <div className="data__bottom">Market Cap</div>
                    </div>
                    <div id="data">
                        <div className="data__top">+5.26%</div>
                        <div className="data__bottom">24 hour price</div>
                    </div>
                    <div id="data">
                        <div className="data__top">111 BTC</div>
                        <div className="data__bottom">24 hour volume</div>
                    </div>
                    <div id="data">
                        <div className="data__top">600,457</div>
                        <div className="data__bottom">active traders</div>
                    </div>
                    <div id="data">
                        <div className="data__top">$200232.15</div>
                        <div className="data__bottom">Live Bitcoin Price</div>
                    </div>
                </div>
                <div className="btns">
                <div className="loginbtn">
                        <div className="loginbtn-img">{login}</div>
                        <div className="loginbtn-text">LOGIN</div>
                    </div>
                    <div className="signupbtn">
                        <div className="signupbtn-img">{signup}</div>
                        <div className="signupbtn-text">REGISTER</div>
                    </div>
                </div>
            </div>
        </main>
     );
}
 
export default NavA;