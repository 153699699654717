import './contract.css'

const Contract = () => {
    const investInfos = [
        {
            plan: 'Bronze Plan',
            percentage: '45%',
            period: '24 Hours',
            minimum: '1,000',
            maximum: '2,900',
            commission: '7%',
            icon: <svg role="img" xmlns="http://www.w3.org/2000/svg" width="38px" height="38px" viewBox="0 0 24 24" aria-labelledby="favouriteIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="favouriteIconTitle">Favourite</title> <path d="M12,21 L10.55,19.7051771 C5.4,15.1242507 2,12.1029973 2,8.39509537 C2,5.37384196 4.42,3 7.5,3 C9.24,3 10.91,3.79455041 12,5.05013624 C13.09,3.79455041 14.76,3 16.5,3 C19.58,3 22,5.37384196 22,8.39509537 C22,12.1029973 18.6,15.1242507 13.45,19.7149864 L12,21 Z"/> </svg>
        },
        {
            plan: 'Bronze Plan',
            percentage: '45%',
            period: '24 Hours',
            minimum: '1,000',
            maximum: '2,900',
            commission: '7%',
            icon: <svg width="38px" height="38px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="wineIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" color="white"> <title id="wineIconTitle">Wine</title> <path d="M12 14C14.7614 14 17 11.7614 17 9C17 7.07098 15.4615 4 15.4615 4H8.53846C8.53846 4 7 6.23858 7 9C7 11.7614 9.23858 14 12 14ZM12 14V20M12 20H8M12 20H16"/> </svg>
        },

         {
            plan: 'Bronze Plan',
            percentage: '45%',
            period: '24 Hours',
            minimum: '1,000',
            maximum: '2,900',
            commission: '7%',
            icon: <svg role="img" xmlns="http://www.w3.org/2000/svg" width="38px" height="38px" viewBox="0 0 24 24" aria-labelledby="toolIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="toolIconTitle">Tool</title> <path d="M9.74292939,13.7429294 C9.19135019,13.9101088 8.60617271,14 8,14 C4.6862915,14 2,11.3137085 2,8 C2,7.07370693 2.20990431,6.19643964 2.58474197,5.4131691 L6.94974747,9.77817459 L9.77817459,6.94974747 L5.4131691,2.58474197 C6.19643964,2.20990431 7.07370693,2 8,2 C11.3137085,2 14,4.6862915 14,8 C14,8.88040772 13.8103765,9.71652648 13.4697429,10.4697429 L20.5858636,17.5858636 C21.3669122,18.3669122 21.3669122,19.6332422 20.5858636,20.4142907 L19.9142907,21.0858636 C19.1332422,21.8669122 17.8669122,21.8669122 17.0858636,21.0858636 L9.74292939,13.7429294 Z"/> </svg>
        }
        
    ]

    const chevron = <svg role="img" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" aria-labelledby="chevronsRightIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="chevronsRightIconTitle">Chevrons Right</title> <polyline points="13 7 18 12 13 17 13 17"/> <polyline points="7 7 12 12 7 17 7 17"/> </svg>

    return ( 
        <main className="contract">
      <div className="contractCards">
                {investInfos.map((info) => (
                    <div className="contractCard">
                        <div className="contractIcon">{info.icon}</div>
                        <div className="contractPlan">{info.plan}</div>
                        <div className="contractPercentage">{info.percentage}</div>
                        <div className="contractPeriod">After {info.period}</div>
                        <div className="contractContent">
                            <div id="contractContent">
                                <p>{chevron}</p>
                                <p>MINIMUM INVESTMENT - ${info.minimum} </p>
                            </div>
                            <div id="contractContent">
                                <p>{chevron}</p>
                                <p>MAXIMUM INVESTMENT - ${info.maximum} </p>
                            </div>
                            <div id="contractContent">
                                <p>{chevron}</p>
                                <p>REFFERAL COMMISSION - {info.commission} </p>
                            </div>
                            <div id="contractContent">
                                <p>{chevron}</p>
                                <p>Instant Withdrawal</p>
                            </div>
                        </div>
                        <p className="btn">Invest Now</p>
                    </div>
                ))}
                
            </div>
        </main> 
    );
}
 

export default Contract;