import './headers.css'


const HeaderB = ({title}) => {
    return ( 
        <main className="headerB">
            <div className="headerb">{title}</div>
        </main>
     );
}
 
 

export default HeaderB;