import HeaderA from './headerA';
import './statistics.css'

const Statistics = () => {
    return ( 
        <main className="statistics">
            <div className="statsrectangle">
                <div className="stats">
                    <div className='borderright'>
                        <p>40+</p>
                        <p>COUNTRIES</p>
                    </div>
                    <div className='borderright'>
                        <p>$10 MILLION+</p>
                        <p>MONTHLY DEPOSITS</p>
                    </div>
                    <div>
                        <p>$25 MILLION+</p>
                        <p>MONTHLY WITHDRAWAL</p>
                    </div>
                </div>
                <div className="statsbtn">
                    <p className="p">SEE PLANS </p>
                    <span>or</span>
                    <p>REGISTER NOW</p>
                </div>
            </div>
        </main>
     );
}
 
export default Statistics;