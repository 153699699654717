import './bitcoin.css'


const Bitcoin = () => {

    const bitcoinInfos = [
        {
            head: "Get Bitcoin Wallet",
            paragraph: "Create an account to get it send and receive bitcoins",
            img:  <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" aria-labelledby="windowIconTitle" stroke="orangered" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="windowIconTitle">Window</title> <rect width="18" height="18" x="3" y="3"/> <line stroke-linecap="round" x1="6" y1="6" x2="6" y2="6"/> <line stroke-linecap="round" x1="9" y1="6" x2="9" y2="6"/> <line stroke-linecap="round" x1="12" y1="6" x2="12" y2="6"/> <path d="M21,9 L3,9"/> </svg>
        },
        {
            head: "Get Coins to Your Wallet",
            paragraph: "Add bitcoins you've created or exchanged via block chain api",
            img:  <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" aria-labelledby="signalAltIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="signalAltIconTitle">Signal</title> <path d="M18 5C11.372583 5 6 10.372583 6 17M18 9C13.581722 9 10 12.581722 10 17M18 13C15.790861 13 14 14.790861 14 17"/> </svg>
        },
        {
            head: "Transact with Wallet",
            paragraph: "Enter receiver's address, specify the amount and send",
            img:  <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" aria-labelledby="paperclipIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="paperclipIconTitle">Paperclip</title> <path d="M7.93517339,13.7795989 L15.1617306,6.55304173 C16.03921,5.67631227 17.4656766,5.67631227 18.343156,6.55304173 C19.2206355,7.43052116 19.2206355,8.85773771 18.343156,9.73521714 L8.40091248,19.5477137 C6.93619681,21.0124294 4.56325242,21.0124294 3.09853675,19.5477137 C1.63382108,18.083748 1.63382108,15.7093037 3.09853675,14.245338 L12.9335328,4.53783896 C14.9839848,2.48738701 18.3094068,2.48738701 20.3568588,4.53783896 C22.4088107,6.58904088 22.4088107,9.91146301 20.3583588,11.961915 L13.2390491,19.0819746"/> </svg>
        },

    ]

    return ( 
        <main className="bitcoin">
            <div className="bitcoinboxes">
                {bitcoinInfos.map((info)=>(
                    <div className='bitcoinbox'>
                        <div className="bitcoinbox__img">{info.img}</div>
                        <div className="bitcoinbox__content">
                            <p>{info.head}</p>
                            <p>{info.paragraph}</p>
                        </div>
                    </div>
                ))}
            </div>
        </main>
     );
}
 
 

export default Bitcoin;