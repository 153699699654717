import './invest.css'

const Invest = () => {
      
    const investInfos = [
        {
            plan: 'Bronze Plan',
            percentage: '45%',
            period: '24 Hours',
            minimum: '1,000',
            maximum: '2,900',
            commission: '7%',
        },
        {
            plan: 'Bronze Plan',
            percentage: '45%',
            period: '24 Hours',
            minimum: '1,000',
            maximum: '2,900',
            commission: '7%',
        },

         {
            plan: 'Bronze Plan',
            percentage: '45%',
            period: '24 Hours',
            minimum: '1,000',
            maximum: '2,900',
            commission: '7%',
        },
        {
            plan: 'Bronze Plan',
            percentage: '45%',
            period: '24 Hours',
            minimum: '1,000',
            maximum: '2,900',
            commission: '7%',
        },
         {
            plan: 'Bronze Plan',
            percentage: '45%',
            period: '24 Hours',
            minimum: '1,000',
            maximum: '22,900',
            commission: '7%',
        },
         
    ]

    const chevron = <svg role="img" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" aria-labelledby="chevronsRightIconTitle" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="chevronsRightIconTitle">Chevrons Right</title> <polyline points="13 7 18 12 13 17 13 17"/> <polyline points="7 7 12 12 7 17 7 17"/> </svg>

    return ( 
        <main className="invest">
            <div className="cards">
                {investInfos.map((info) => (
                    <div className="card">
                        <div className="plan">{info.plan}</div>
                        <div className="percentage">{info.percentage}</div>
                        <div className="period">After {info.period}</div>
                        <div className="content">
                            <div id="content">
                                <p>{chevron}</p>
                                <p>MINIMUM INVESTMENT - ${info.minimum} </p>
                            </div>
                            <div id="content">
                                <p>{chevron}</p>
                                <p>MAXIMUM INVESTMENT - ${info.maximum} </p>
                            </div>
                            <div id="content">
                                <p>{chevron}</p>
                                <p>REFFERAL COMMISSION - {info.commission} </p>
                            </div>
                            <div id="content">
                                <p>{chevron}</p>
                                <p>Instant Withdrawal</p>
                            </div>
                        </div>
                        <p>Invest Now</p>
                    </div>
                ))}
                
            </div>
        </main>
     );
}
 

export default Invest;