import './headers.css'


const HeaderA = ({titleAstart, titleAend, paragraph}) => {
    return ( 
        <main className="headerA">
            <div className="headera">
                <p><span>{titleAstart} </span><span className="orangeheader">{titleAend}</span></p>
                {paragraph && 
                    <div className="lines">
                        <span className="line"></span>
                        <p>{paragraph}</p>
                        <span className="line"></span>
                    </div>
                }
            </div>
        </main>
     );
}
 
 

export default HeaderA;